@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: "F37Bolton", sans-serif;
}

input,
button {
    --webkit-focus-ring-color: transparent;
}

input,
button:focus-visible {
    outline: none;
}

/*Globe Radio */

input[type="radio"] {
    --s: 16px;
    /* control the size */
    --c: #088ab2;
    /* the active color */

    height: var(--s);
    width: var(--s);

    aspect-ratio: 1;
    border: calc(var(--s) / 6) solid var(--c);
    padding: calc(var(--s) / 6);
    background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0 no-repeat content-box;
    border-radius: 50%;
    outline-offset: calc(var(--s) / 10);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    font-size: inherit;
    transition: 0.3s;
}

.custom-grid {
    grid-template-columns: auto auto auto auto;
}

input[type="radio"]:checked {
    border-color: var(--c);
    background-size: 100% 100%;
}

input[type="radio"]:disabled {
    background: linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
    opacity: 0.5;
    cursor: not-allowed;
}

@media print {
    input[type="radio"] {
        -webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto;
        background: none;
    }
}

/*Globe Radio*/

input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 2px 0 0;
    color: white;
    width: 16px;
    height: 16px;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #088ab2;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:checked {
    background: #ecfdff;
    border-color: #088ab2;
}

input[type="checkbox"]:hover {
    background: #ffff;
    border-color: #d0d5dd;
}

.setOP > div > div > * {
    opacity: 0.4;
    transition: opacity 0.5s ease-in-out;
}

.setOP > div > div:nth-last-child(3) > * {
    opacity: 0.6;
}

.setOP > div > div:nth-last-child(2) > * {
    opacity: 0.8;
}

.setOP > div > div:nth-last-child(1) > * {
    opacity: 0.95;
}

.setOP > div > div:last-child > * {
    opacity: 1;
}

.scroll-bottom {
    /*max-height: 100vh;*/
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.comeInAnimation {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.fadeInBottom {
    animation-name: fadeInBottom;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
        animation-iteration-count: infinite;
    }

    to {
        opacity: 1;
        animation-iteration-count: infinite;
    }
}

.page-layout {
    margin: 100px 50px;
}

.tooltip-heading {
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
}

.qty,
.date {
    display: flex;
    color: #ffffff;
    font-size: 14px;
    justify-content: space-between;
    position: relative;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inherit;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d0d5dd;
    border-radius: 6px;
}

/* Handle */
.semi-automated::-webkit-scrollbar-thumb {
    background: #eaecf0;
    border-radius: 6px;
}

.recharts-cartesian-axis-line {
    display: none;
}

.recharts-cartesian-axis-ticks {
    font-size: 10px;
}

.recharts-layer .recharts-cartesian-axis-tick {
    font-size: 6px;
    color: #98a2b3;
}

.scroll-hidden::-webkit-scrollbar {
    display: none;
}

/* @media only screen and (min-width: 1180px) and (max-width: 1440px) {
.table-view{
 flex: 1;
}
} */

@media only screen and (min-width: 1440px) {
    .table-view {
        flex: 1;
    }
}

@layer utilities {
    .scroll-smooth {
        scroll-behavior: smooth;
    }

    .scroll-transition {
        transition: scroll 0.3s ease-in-out;
    }
}

.notification-scroll::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    /* Adjust as needed */
    border-radius: 6px;
    padding-top: 10px;
}
.notification-scroll::-webkit-scrollbar-track {
    margin: 10px 0 12px; /* Adds some space around the scrollbar track */
}

/* Add this to your CSS file or style block */
.scrollbar-pr::-webkit-scrollbar {
    width: 4px;
    /* Adjust as needed */
}

.scrollbar-pr::-webkit-scrollbar-thumb {
    background-color: #1d2939;
    /* Adjust as needed */
    border-radius: 6px;
}

.scrollbar-pr::-webkit-scrollbar-track {
    background-color: transparent;
    /* Adjust as needed */
    border-radius: 6px;
}

.custom.scrollbar-pr::-webkit-scrollbar-track {
    background-color: transparent;
    /* Adjust as needed */
    border-radius: 6px;
    margin-top: 18px;
}

/* Trackpack modal scrollbar  */

.trackpack-modal-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(234, 236, 240, 1);
    /* Adjust as needed */
}

/* modal scrollbar  */

.modal-scrollbar-pr::-webkit-scrollbar {
    width: 4px;
    /* Adjust as needed */
    margin-left: 7px;
}

.modal-scrollbar-pr::-webkit-scrollbar-thumb {
    background-color: rgba(208, 213, 221, 1);
    /* Adjust as needed */
    border-radius: 6px;
}

.modal-scrollbar-pr::-webkit-scrollbar-track {
    background-color: transparent;
    /*Adjustasneeded*/
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

/* modal scrollbar end */

.customScroll::-webkit-scrollbar-thumb {
    background-color: hsla(240, 7%, 62%, 1);
    /* Adjust as needed */
    border-radius: 6px;
}

.productCardScroll::-webkit-scrollbar-thumb {
    background-color: #d0d5dd;
    border-radius: 8px;
}

/* tooltip */

:root .tooltip-style {
    --rt-opacity: 1;
    padding: 0;
    --rt-color-dark: #1d2939;
    z-index: 50;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    box-shadow: 0px 4px 8px 4px #1018281a;
}

.leading-class {
    /* leading-trim:both;
    text-edge: cap; */
}

@media screen and (max-width: 1300px) {
    .compitator-input input {
        height: 35px;
    }

    .compitator-input input::placeholder {
        font-size: 14px;
    }

    .compitator-text {
        font-size: 14px;
    }

    .compitator-text span {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .hidden-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .onBoardingFooter {
        position: fixed;
        bottom: 20px;
        width: 90%;
    }
}

.top-head:first-child {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
}

.top-scroll::-webkit-scrollbar-thumb {
    background: #d0d5dd;
}

.scroll-hidden::-webkit-scrollbar {
}

.right-border {
    position: relative;
}

.right-border::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    /* Adjust the width of the border */
    background: #eaecf0;
}

.left-border {
    position: relative;
}

.left-border::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    /* Adjust the width of the border */
    background: #eaecf0;
}

.graph tspan {
    font-size: 8px;
}

.tag-input .css-11f1908-control,
.css-ytuond-control {
    padding: 10px 14px 10px 11px;
    min-height: 44px;
    height: auto;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    text-transform: uppercase;
    box-shadow:
        0px 8px 8px -4px #10182808,
        0px 20px 24px -4px #10182814;
}

.tag-input .css-1fdsijx-ValueContainer {
    padding: 0;
}

.tag-input .css-l58daa-multiValue {
    margin: 0;
    gap: 4px;
    padding: 2px 6px;
}

.tag-input .css-1o1s9o6-MultiValueRemove {
    padding: 0;
    color: #b42318;
}

.css-tj5bde-Svg {
    width: 16px;
    height: 16px;
    margin: 2px 0;
    fill: #b42318;
    stroke: #b42318;
}

.tag-input .css-13uu7yx-MultiValueGeneric {
    padding: 0;
    padding-top: 2px;
}

.tag-input .css-hlgwow {
    flex: initial;
    padding: 0;
    position: relative;
    /* top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); */
}

.tag-input .css-1kp4486:hover {
    background-color: transparent;
}

.tag-input .idyz3mf {
    position: relative;
    padding: 0;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.tag-input .css-1wy0on6 {
    display: initial;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    z-index: 1000;
    width: 16px;
    height: 16px;
    right: 10px;
}

.tag-input .css-4ob127 {
    padding: 0;
    margin: 0;
    min-height: auto;
    height: auto;
}

.tag-input .css-x79r3p {
    padding: 0;
    margin: 0;
    height: auto;
    min-height: auto;
}

.tag-input .css-1dyz3mf {
    padding: 0;
    margin: 0;
    min-height: auto;
    height: auto;
    display: initial;
    flex: initial;
    position: static;
    display: flex;
    gap: 8px;
    align-items: flex-start;
}

.tag-input .css-ytuond-control {
    padding: 0 14px 0 14px;
    min-height: 44px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.tag-input .css-11f1908-control .css-1fdsijx-ValueContainer,
.css-3w2yfm-ValueContainer {
    padding: 0;
}

.tag-input .css-nzbz5p {
    padding: 0;
    padding-top: 1px;
    font-weight: 400;
    text-transform: uppercase;
}

.tag-input .css-8mmkcg {
    fill: #344054;
    display: none;
}

.tag-input .css-1kp4486 {
    padding: 0;
    width: 16px;
    height: 16px;
}

.tag-input .css-1kp4486::before {
    content: "";
    display: block;
    opacity: 1;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDRMNCAxMk00IDRMMTIgMTIiIHN0cm9rZT0iIzM0NDA1NCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
}

.tag-input .css-n26rjb {
    padding: 0;
    padding-top: 1px;
    font-weight: 400;
    text-transform: uppercase;
}

.tag-input .css-u525p3 {
    padding: 0;
}

.tag-input .css-u525p3::before {
    content: "";
    display: block;
    opacity: 1;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDRMNCAxMk00IDRMMTIgMTIiIHN0cm9rZT0iI0I0MjMxOCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
}

.tag-input .css-8mmkcg {
    fill: #344054;
    display: none;
}

.tag-input .css-3w2yfm-ValueContainer {
    padding: 0;
}

.tag-input .css-1jqq78o-placeholder {
    color: #d0d5dd;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.tag-input .css-10e0i6w-Input {
    padding: 0;
    margin: 0;
    min-height: auto;
    height: auto;
}

.tag-input .css-3w2yfm-ValueContainer {
    padding: 0;
    gap: 8px;
}

.tag-input .css-16hnrbp-Input {
    padding: 0;
    margin: 0;
    min-height: auto;
    height: auto;
}

.tag-input .css-1aqtahh-multiValue {
    margin: 0;
    background-color: #f2f4f7;
    padding: 2px 6px;
    gap: 4px;
    align-items: center;
}

.tag-input .css-9azvnm-MultiValueGeneric {
    padding: 0;
    padding-top: 2px;
    text-transform: uppercase;
}

.tag-input .css-1tdaddk-MultiValueRemove {
    background-color: initial;
    color: initial;
    padding: 0;
}

.tag-input .css-1tdaddk-MultiValueRemove .css-tj5bde-Svg {
    fill: #344054;
}

.tag-input .css-1tdaddk-MultiValueRemove:hover {
    background-color: initial;
    color: initial;
    padding: 0;
}

.last-child:last-child {
    margin-bottom: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

/* input[type="checkbox"].trackpack-checkbox:checked {
    border: 0.6px solid #088ab2;
    background-color: #ECFDFF;
} */

/* Hide the default checkbox */
.trackpack-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

/* Create the custom checkmark (dash) */
.custom-checkbox .checkmark {
    margin-left: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 0.01rem solid #d0d5dd;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

/* When the checkbox is checked, add a blue background and white dash */
.custom-checkbox input:checked + .checkmark {
    background-color: #ecfdff;
    border-color: #088ab2;
    border-width: 0.01rem;
}
#one {
    border-width: 0.5px;
}

.custom-checkbox input:checked + .checkmark::after {
    content: "";
    position: absolute;
    left: 4.5px;
    top: 1.7px;
    width: 4px;
    height: 8px;
    border: solid #088ab2;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}

input[type="checkbox"]:indeterminate::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 0.6px;
    background-color: #088ab2;
}

input[type="checkbox"]:indeterminate {
    background: #ecfdff;
    border-color: #088ab2;
}
input[type="checkbox"]:indeterminate:hover {
    background: #ecfdff !important;
    border-color: #088ab2 !important;
}
#one {
    cursor: pointer;
}

input[type="checkbox"]#one:checked {
    border: 1px solid #088ab2 !important;
}
input[type="checkbox"]#one:checked:before {
    content: "";
    position: absolute;
    left: 2.5px;
    top: -0.6px;
    width: 4px;
    height: 8px;
    border: solid #088ab2;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    box-shadow: initial;
    clip-path: initial;
}

input[type="checkbox"]#one:checked {
    background-color: #ecfdff;
}

input[type="checkbox"]#one:hover {
    background: #fff;
    border-color: #d0d5dd;
}

input[type="checkbox"]#one:checked:hover {
    background: #ecfdff;
    border-color: #088ab2;
}

/* TAG INPUT STYLE */

#country-input .css-1fdsijx-ValueContainer {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}
#country-input .css-1xc3v61-indicatorContainer {
    padding: 0;
}
#country-input .css-1nmdiq5-menu {
    border-radius: 12px;
    border: 1px solid #d0d5bd;
    padding: 6px 4px 0px 6px;
    /* padding-right: 4px; */
    height: 184px;
    margin-top: 4px;
    /* width: calc(100% - 4px); */
    outline: initial;
    box-shadow:
        0px 4px 6px -2px #10182808,
        0px 12px 16px -4px #10182814;
}
#country-input .css-1nmdiq5-menu > div {
    /* width: calc(100% - 10px); */
    height: 176px;
    overflow-y: auto;
}

#country-input .css-1nmdiq5-menu > div > div:hover {
    padding: 6px 10px 6px 8px;
    background-color: #f2f4f7;
    border-radius: 8px;
    margin-right: 6px;
}

#country-input .css-1xc3v61-indicatorContainer {
    position: relative;
}
#country-input .css-tj5bde-Svg {
    display: none;
}

#country-input .css-1xc3v61-indicatorContainer::before {
    content: "";
    display: block;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgNy41TDEwIDEyLjVMMTUgNy41IiBzdHJva2U9IiM2NjcwODUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K")
        no-repeat center;
    width: 20px;
    height: 20px;
}
#country-input .css-15lsz6c-indicatorContainer {
    padding: 0;
    margin: 0;
}
#country-input .css-15lsz6c-indicatorContainer::before {
    content: "";
    display: block;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgNy41TDEwIDEyLjVMMTUgNy41IiBzdHJva2U9IiM2NjcwODUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K")
        no-repeat center;
    width: 20px;
    height: 20px;
}
#country-input .css-1jqq78o-placeholder {
    color: #344054;
    font-size: 16px;
    margin: 0;
}

#country-input .css-8mmkcg {
    display: none;
}
#country-input .css-hlgwow {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

@media screen and (min-width: 1024px) {
    .h-custom {
        height: calc(100vh - 386px);
    }
}
@media screen and (min-width: 1921px) {
    .h-custom {
        height: calc(100vh - 387px);
    }
}

#checkboxDefault::before {
    display: none;
}

input[type="checkbox"]#checkboxDefault:checked {
    background: #ecfdff;
    border-color: #088ab2;
}
.gradient-border {
    position: relative; /* Ensure the pseudo-element is positioned correctly */
    cursor: pointer; /* Optional: Change cursor to pointer on hover */
    border: none; /* Remove default border */
}

.gradient-border::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px; /* Apply border radius to the pseudo-element */
    border: 1px solid #a5f0fc;
}

.custom-shadow {
    box-shadow:
        0px 4px 6px -2px #10182808,
        0px 12px 16px -4px #10182814;
}

.tag-input .css-1hb7zxy-IndicatorsContainer {
    display: initial;
}

.tag-input .css-1hb7zxy-IndicatorsContainer svg {
    align-items: center;
    justify-content: end;
    display: flex;
    margin: 4px 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eaecf0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #06aed4;
}

/* input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
} */

input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 12px;
}

.slider.round:before {
    border-radius: 50%;
    box-shadow:
        0px 1px 2px 0px #1018280f,
        0px 1px 3px 0px #1018281a;
}
.tp-icon {
    width: 10px;
    height: 10px;
}
.tp-icon svg {
    width: 100%;
    height: 100%;
}

.market-icon {
    width: 20px;
    height: 20px;
}
.market-icon svg {
    width: 100%;
    height: 100%;
}
.custom-span > span {
    display: flex;
}
/* .progress-bar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    position: relative;
    max-width: 608px;
    width: 100%;
}

.progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    transition: all 0.5s ease-in-out;
    flex: 0 0 19.8%;
    text-align: center;
    position: relative;
}

.step-indicator {
    font-size: 24px;
    margin-bottom: 5px;
    color: #ccc;
    position: relative;
    z-index: 1;
}


.step-indicator::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%; 
    display: inline-block;
    width: calc(100% + 115px); 
    height: 2px;
    background-color: #eaecf0;
    z-index: 0; 
}

.step-indicator.completed::after {
    background-color: #088ab2;
}

.progress-step:last-child .step-indicator::after {
    display: none;
}

.completed .step-indicator {
    color: #00b0ff; 
}

.step-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
} */

.no-select {
    user-select: none; /* Disable text selection */
}

/* Handle */
.semi-automated::-webkit-scrollbar-thumb {
    background: #eaecf0;
    border-radius: 6px;
}

/* Handle */
.carousel-scroll::-webkit-scrollbar-thumb {
    background: #f2f4f7;
    border-radius: 6px;
}

.switches {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 22px;
}

.switches input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sliders {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #eaecf0;
}

.sliders:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: #eaecf0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .sliders {
    background-color: #06aed4;
}

/* input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
} */

input:checked + .sliders:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    background-color: #fff;
}

/* Rounded sliders */
.sliders.rounds {
    border-radius: 12px;
}

.sliders.rounds:before {
    border-radius: 50%;
    /* box-shadow:
        0px 1px 2px 0px #1018280f,
        0px 1px 3px 0px #1018281a; */
}

.scrollable-container {
    overflow-y: auto;
    max-height: calc(100vh - 334px); /* Adjust based on your layout */
}
